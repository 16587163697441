<template>
  <div class="progress">
    <div class="progress-bar">
      <div class="progress-bar-inner" :style="{
          'width': `${(singTime / 60) * barWidth}px`
        }">
        <!-- <div class="progress-bar-inner-current">{{ singTime > 60 ? 60 : singTime }}min</div> -->
      </div>
      <div class="progress-bar-inner-10">10min</div>
      <div class="progress-bar-inner-20">20min</div>
      <div class="progress-bar-inner-30">30min</div>
      <div class="progress-bar-inner-40">40min</div>
      <div class="progress-bar-inner-50">50min</div>
      <div class="progress-bar-inner-60">60min</div>
      <img class="progress-bar-inner-10-img" :src="progressPointIcon[singTime < 10 ? 'inactive' : 'active']" alt="">
      <img class="progress-bar-inner-20-img" :src="progressPointIcon[singTime < 20 ? 'inactive' : 'active']" alt="">
      <img class="progress-bar-inner-30-img" :src="progressPointIcon[singTime < 30 ? 'inactive' : 'active']" alt="">
      <img class="progress-bar-inner-40-img" :src="progressPointIcon[singTime < 40 ? 'inactive' : 'active']" alt="">
      <img class="progress-bar-inner-50-img" :src="progressPointIcon[singTime < 50 ? 'inactive' : 'active']" alt="">
      <img class="progress-bar-inner-60-img" :src="progressPointIcon[singTime < 60 ? 'inactive' : 'active']" alt="">
    </div>
    <div class="progress-btn">
      <div class="progress-btn-10">
        <span class="progress-btn-title">1h VIP</span>
        <div v-if="singTime >= 10" class="progress-btn-button" :class="[(singTime >= 10 && !collectStatus[0]) && 'active']" @click="handleCollect('1')">
          {{ collectStatus[0] ? '已领取' : '待领取'}}
        </div>
        <div v-else class="progress-btn-button progress-btn-button-nolock">
          未解锁
        </div>
      </div>
      <div class="progress-btn-20">
        <span class="progress-btn-title">3h VIP</span>
        <div v-if="singTime >= 20" class="progress-btn-button" :class="[(singTime >= 20 && !collectStatus[1]) && 'active']" @click="handleCollect('3')">
          {{ collectStatus[1] ? '已领取' : '待领取'}}
        </div>
        <div v-else class="progress-btn-button progress-btn-button-nolock">
          未解锁
        </div>
      </div>
      <div class="progress-btn-30">
        <span class="progress-btn-title">5h VIP</span>
        <div v-if="singTime >= 30" class="progress-btn-button" :class="[(singTime >= 30 && !collectStatus[2]) && 'active']" @click="handleCollect('5')">
          {{ collectStatus[2] ? '已领取' : '待领取'}}
        </div>
        <div v-else class="progress-btn-button progress-btn-button-nolock">
          未解锁
        </div>
      </div>
      <div class="progress-btn-40">
        <span class="progress-btn-title">7h VIP</span>
        <div v-if="singTime >= 40"  class="progress-btn-button" :class="[(singTime >= 40 && !collectStatus[3]) && 'active']" @click="handleCollect('7')">
          {{ collectStatus[3] ? '已领取' : '待领取'}}
        </div>
        <div v-else class="progress-btn-button progress-btn-button-nolock">
          未解锁
        </div>
      </div>
      <div class="progress-btn-50">
        <span class="progress-btn-title">9h VIP</span>
        <div v-if="singTime >= 50"  class="progress-btn-button" :class="[(singTime >= 50 && !collectStatus[4]) && 'active']" @click="handleCollect('9')">
          {{ collectStatus[4] ? '已领取' : '待领取'}}
        </div>
        <div v-else class="progress-btn-button progress-btn-button-nolock">
          未解锁
        </div>
      </div>
      <div class="progress-btn-60">
        <span class="progress-btn-title">11h VIP</span>
        <div v-if="singTime >= 60"  class="progress-btn-button" :class="[(singTime >= 60 && !collectStatus[5]) && 'active']" @click="handleCollect('11')">
          {{ collectStatus[5] ? '已领取' : '待领取'}}
        </div>
        <div v-else class="progress-btn-button progress-btn-button-nolock">
          未解锁
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { checkLandscapeOrPortrait } from '@/utils/device'

export default {
  name: 'ProgressComponent',
  props: {
    singTime: {
      type: Number,
      default: 0
    },
    collectStatus: {
      type: Array,
      default: () => [false, false, false, false, false, false]
    }
  },
  setup(props, { emit }) {
    // let singTime = ref(0)
    // let collectStatus = ref([0, 0, 0])
    let barWidth = ref(1680)
    onMounted(() => {
      barWidth.value = checkLandscapeOrPortrait() === 'landscape' ? 1680 : 1000
    })

    const progressPointIcon = ref({
      inactive: 'https://qncweb.ktvsky.com/20230714/vadd/8bd907536a0abb5c8a7fe71f3daca363.png',
      active: 'https://qncweb.ktvsky.com/20230714/vadd/3e09b34d61a11024a3a85882965d3c6d.png'
    })

    const handleCollect = (val) => {
      
      emit('collect', val)
    }

    return {
      // singTime,
      // collectStatus,
      barWidth,
      handleCollect,
      progressPointIcon,
    }
  }
}
</script>

<style lang="stylus" scoped>
.progress
  width 100%
  height 300px
  margin-top 195px
  display flex
  flex-direction column
  align-items center
  // justify-content center
  &-bar
    width 1680px
    height 20px
    border-radius 10px
    background rgba(255, 255, 255, 0.2)
    position relative
    @media screen and (max-width 1200px)
      width 1000px
    &-inner
      width 0
      max-width 1680px
      height 100%
      background-image linear-gradient(90deg, #F0D290 0%, #F9EBC3 100%)
      border-radius 11.5px
      position relative
      @media screen and (max-width 1200px)
        max-width 1000px
      &-current
        width 80px
        height 48px
        color rgba(0, 0, 0, 1)
        text-align center
        line-height 42px
        position absolute
        top -60px
        right -36px
        background url(https://qncweb.ktvsky.com/20230714/vadd/ff2251a83317872c1e5969a5152c1218.png) no-repeat
        background-size 100% 100%
        background-position center
      &-10
        position absolute
        top -52px
        left calc(16.6% - 45px)
        font-size 28px
        color rgba(255, 255, 255, 0.2)
        &-img
          position absolute
          top -10px
          left calc(16% - 19px)
          width 38px
          height 38px
      &-20
        position absolute
        top -52px
        left calc(33.3% - 38px)
        font-size 28px
        color rgba(255, 255, 255, 0.2)
        &-img
          position absolute
          top -10px
          left calc(33.3% - 19px)
          width 38px
          height 38px
      &-30
        position absolute
        top -52px
        left calc(50% - 38px)
        font-size 28px
        color rgba(255, 255, 255, 0.2)
        &-img
          position absolute
          top -10px
          left calc(50% - 19px)
          width 38px
          height 38px
      &-40
        position absolute
        top -52px
        left calc(66.7% - 38px)
        font-size 28px
        color rgba(255, 255, 255, 0.2)
        &-img
          position absolute
          top -10px
          left calc(66.7% - 19px)
          width 38px
          height 38px
      &-50
        position absolute
        top -52px
        left calc(83.3% - 38px)
        font-size 28px
        color rgba(255, 255, 255, 0.2)
        &-img
          position absolute
          top -10px
          left calc(83.3% - 19px)
          width 38px
          height 38px
      &-60
        position absolute
        top -52px
        right -20px
        font-size 28px
        color rgba(255, 255, 255, 0.2)
        &-img
          position absolute
          top -10px
          right 0
          width 38px
          height 38px
  &-btn
    width 1680px
    height 120px
    position relative
    @media screen and (max-width 1200px)
      width 1000px
    &-title
      color rgba(240, 210, 144, 1)
      font-size 28px
    &-button
      width 200px
      height 80px
      margin-left -60px
      margin-top 30px
      text-align center
      line-height 80px
      background rgba(30, 31, 33, 0.8)
      border-radius 10px
      color rgba(255, 255, 255, 0.2)
      font-size 30px
      pointer-events none
      @media screen and (max-width 1200px)
        width 150px
        height 78px
        margin-left -30px
        font-size 26px
      &-nolock
        background transparent
    &-10
      position absolute
      top 11px
      left calc(16% - 42px)
    &-20
      position absolute
      top 11px
      left calc(33.3% - 42px)
    &-30
      position absolute
      top 11px
      left calc(50% - 42px)
    &-40
      position absolute
      top 11px
      left calc(66.7% - 42px)
    &-50
      position absolute
      top 11px
      left calc(83.3% - 42px)
    &-60
      position absolute
      top 11px
      right -70px
      @media screen and (max-width 1200px)
        right -50px
    .active
      color rgba(0, 0, 0, 0.8) !important
      background linear-gradient(90deg, #F0D290 0%, #F9EBC3 100%) !important
      border-radius 10px !important
      pointer-events auto !important
</style>