<template>
  <div class="sing-task page">
    <HeadBar class="headbar" title="唱歌送会员"></HeadBar>
    <div class="sing-task-content">
      <Progress
        :singTime="singTime"
        :collectStatus="collectStatus"
        @collect="handleCollect"
      />
    </div>
    <div class="sing-task-intro">
      <p>活动规则介绍：</p>
      <p>1.此任务为不定期福利任务，任务期间将根据每位用户的演唱时长总和发放奖励；</p>
      <p>2.奖励为VIP会员时效，会根据不同完成度（演唱时长）领取对应奖励；</p>
      <p>3.任务于2023年8月1日开始，于2023年8月31日结束，任务结束后奖励不可领取，进度不进行累积；</p>
      <p>4.此任务必须在登录状态下进行，只有登录后的演唱行为才会计入演唱时长。</p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import Progress from './components/progress'
import { getSingTaskStatus, getSingTaskVip } from '@/service/singTask'
import get from 'lodash/get'
import { Toast } from 'vant'
import { sendLog } from '@/directives/v-log/log'
import { nanoid } from 'nanoid'

export default {
  name: 'SingTaskPage',
  components: {
    Progress,
  },
  setup() {
    const store = useStore()
    let singTime = ref(0)
    // let collectStatus = ref([0, 0, 0])
    const unionid = computed(() => store.state.userInfo.unionid)
    const collectStatus = computed(() => store.state.singTime.singTaskGetStatus)
    const videoPaused = computed(() => store.state.videoPaused)
    
    let singTimeTimer = null
    onMounted(async () => {
      // 加载活动任务接口状态数据
      singTime.value = await store.dispatch('singTime/updateSingTaskStatus', unionid.value)
      // 延迟同步 防止本地时长已可领取而服务端记录的时长还不可领取的情况
      if (!videoPaused.value) {
        singTimeTimer = setInterval(() => {
          singTime.value = Math.floor(Number(store.state.singTime.singTaskTime) / 60)
        }, 32 * 1000)
      }
    })

    onBeforeUnmount(() => {
      if (singTimeTimer) {
        clearInterval(singTimeTimer)
        singTimeTimer = null
      }
    })

    // const handleInitData = async () => {
    //   const res = await getSingTaskStatus({
    //     unionid: unionid.value
    //   })
    //   if (res.time) {
    //     singTime.value = get(res, 'time', 0) / 60
    //     let task_info = get(res, 'task_info', [false, false, false])
    //     collectStatus.value = [task_info[1], task_info[2], task_info[3]]
    //   }
    // }

    // 领取
    const handleCollect = async (val) => {
      const nanoLog = nanoid(32).toUpperCase()
      sendLog({
        event_type: 'custom',
        event_name: 1727,
        event_data: {
          str1: val,
          str2: nanoLog
        },
      });
      const res = await getSingTaskVip({
        unionid: unionid.value,
        free_day: val
      })
      if (res) {
        Toast(res)
        setTimeout(async () => {
          singTime.value = await store.dispatch('singTime/updateSingTaskStatus', unionid.value)
        }, 1500)
      }
    }

    return {
      singTime,
      collectStatus,
      handleCollect,
    }
  }
}
</script>

<style lang="stylus" scoped>
.sing-task
  color #fff
  background #000
  padding-top 35px
  position relative
  &-content
    width 100%
    height 100%
  &-intro
    position absolute
    left 80px
    bottom 88px
    font-size 28px
    color rgba(255, 255, 255, 0.5)
    @media screen and (max-width 1200px)
      bottom auto
      top 560px
    p
      height 32px
      margin-bottom 16px
      &:nth-child(1)
        margin-bottom 30px
      &:nth-child(5)
        margin-bottom 0px
      @media screen and (max-width 1200px)
        &:nth-child(4)
          height auto
</style>